import React, { Component } from 'react';

export class AboutUs extends Component {
  static displayName = AboutUs.name;

  render() {
    return (
      <div className='containerStyle'> 
        <div className='rowStyle'>
          <div className='mainTxt'>
            Information about company
          </div>
          <img  className='imgStyle' src='https://live.staticflickr.com/65535/52853389563_b5ebf583f7_z.jpg'/>
        </div>

        <div className='subTxtStyle'>
          Продукти які ми представляємо
        </div>

        <div className='contactStyle'>
          Контактні дані
        </div>
    </div>
    );
  }
}
