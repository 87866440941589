import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
    <div className='containerStyle'> 
      <div className='rowStyle'>
        <div className='mainTxt'>
          Information about company
        </div>
        <img  className='imgStyle' src='https://live.staticflickr.com/65535/52853389563_b5ebf583f7_z.jpg'/>
      </div>
      
      <div className='subTxtStyle'>
        Продукти які ми представляємо
      </div>

      <div className='contactStyle'>
        Контактні дані
      </div>
    </div>
    );
  }
}
